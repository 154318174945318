import { bind } from 'lodash-decorators';
import { action, computed, observable, reaction } from 'mobx';
import { component, initialize } from 'tsdi';

export type Language = { label: string; path: string; hashedPath: string };

export type CountryCodes = keyof typeof countryLocaleMapping | '';

export const countryLocaleMapping = {
    DE: 'de-DE',
    AT: 'de-DE',
    PL: 'pl-PL',
    ES: 'es-ES',
    IT: 'it-IT',
    FR: 'fr-FR',
    EN: 'en-GB',
    UK: 'en-GB',
    NL: 'nl-NL'
} as const;

export const localesPathMappings = {
    'de-DE': 'de',
    'en-GB': 'en',
    'en-CA': 'en',
    'en-HK': 'en',
    'it-IT': 'it',
    'ru-RU': 'ru',
    'pl-PL': 'pl',
    'sv-SE': 'sv',
    'tr-TR': 'tr',
    'es-ES': 'es',
    'es-CO': 'es',
    'fr-FR': 'fr',
    'fr-CA': 'fr',
    'nl-NL': 'nl',
    'cs-CZ': 'cs',
    'sl-SI': 'sl',
    'hu-HU': 'hu',
    'ro-RO': 'ro',
    'nb-NO': 'nb'
} as const;

export const locallySupportedLocales = [
    'de-DE',
    'en-GB',
    'en-CA',
    'en-HK',
    'it-IT',
    'ru-RU',
    'pl-PL',
    'sv-SE',
    'tr-TR',
    'es-ES',
    'es-CO',
    'fr-FR',
    'fr-CA',
    'nl-NL',
    'cs-CZ',
    'sl-SI',
    'hu-HU',
    'ro-RO',
    'nb-NO'
] as const;

const getLocaleForTranslations = (locale: string) => {
    const [lang] = locale.split('-');
    const localeOrFallback =
        locallySupportedLocales.find(l => l.startsWith(lang)) ||
        locallySupportedLocales[0];
    return localeOrFallback;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replacePlaceHolder(translated: string, params: any): string {
    if (!params) {
        return translated;
    }
    return translated
        .replace(/\${/g, '___dollar___bracket___')
        .replace(/{\w}/g, v => params[v[1]])
        .replace(/___dollar___bracket___/g, '${')
        .replace(
            // MLWC-9906 workaround for broken check in convert to icu process
            /(%\d\$s)/g,
            v => params[`${parseInt(v.replace('%', ''), 10) - 1}`]
        );
}

@component
export class I18n {
    @observable
    public currentCountry: CountryCodes = '';

    @observable
    public currentLocale: string | undefined;

    @observable
    public version = 0;

    @observable
    public translate: (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ) => string = () => '';

    private localeChangeTimeoutId: ReturnType<typeof setTimeout> | undefined;

    @initialize
    public init(): void {
        reaction(
            () => this.currentCountry,
            currentCountry => {
                this.setCurrentLocale(currentCountry);
            },
            { fireImmediately: true }
        );

        reaction(
            () => this.currentLocale,
            locales => {
                if (locales) {
                    this.loadTranslationBundle(locales);
                }
            },
            { fireImmediately: true }
        );
    }

    @action
    private setCurrentLocale(country: CountryCodes) {
        if (country) {
            if (this.localeChangeTimeoutId) {
                clearTimeout(this.localeChangeTimeoutId);
            }
            this.currentLocale = countryLocaleMapping[country] ?? 'de-DE';
        } else {
            this.localeChangeTimeoutId = setTimeout(() => {
                this.currentLocale = window.navigator.language;
            }, 100);
        }
    }

    @computed
    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    }

    private async loadTranslationBundle(localeParam: string): Promise<void> {
        if (typeof localeParam !== 'string') {
            return;
        }
        const loc = localeParam.replace(/"/g, '');
        const localeOrFallback = getLocaleForTranslations(loc);

        const source: { [key: string]: string } = await (
            await fetch(
                `https://intl.sportalliance.com/ml-try-magicline-webclient/${localesPathMappings[
                    localeOrFallback
                ] || 'en'}.json`
            )
        ).json();

        const translate = (
            key: string | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            params: any
        ): string => {
            if (!key) {
                return '';
            }
            const translated = source[key];
            if (!translated) {
                return key.toString();
            }

            return replacePlaceHolder(translated, params);
        };

        this.translate = this.wrap(translate);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).translate = translate;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((window as any).ml = (window as any).ml || {})['i18n-store'] = this;

        this.hasTranslation = key => Boolean(key && source[key]);
        this.version++;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public hasTranslation: (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ) => boolean = () => false; // eslint-disable-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style

    @bind
    public formatNumber(num: number) {
        const formatter = new Intl.NumberFormat(this.currentLocale, {});

        return formatter.format(num);
    }

    @bind
    public formatYearMonth(yearMonth: string) {
        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            month: 'long'
        });
        const [year, month] = yearMonth.split('-');
        const monthFormatted = formatter.format(
            new Date(1970, Number(month) - 1)
        );

        return `${monthFormatted} ${year}`;
    }

    @bind
    public formatDateTime(date?: Date) {
        return date ? this.dateTimeFormat.format(date) : '-';
    }

    @bind
    public formatDate(date?: string) {
        if (!date) {
            return '-';
        }

        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formatter.format(new Date(date));
    }

    @observable
    public __ = (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ): string =>
        // eslint-disable-next-line
        this.translate(key, ...params);

    private wrap(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (key: string | null | undefined, ...params: any[]) => string
    ): (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ) => string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const convertParams = (params: any[]) => {
            // note: allowed values for params are
            // [ 'value1', 'value2', 'value3' ] and
            // [ { '0': 'value1', '1': 'value2', '2': 'value3' } ]
            // params as string-array will be transformed to the latter syntax.
            if (params[0] !== undefined && typeof params[0] !== 'object') {
                // eslint-disable-next-line no-param-reassign
                params = [
                    params.reduce(
                        (
                            memo: { [key: string]: string | number },
                            param: string | number,
                            i: number
                        ) => {
                            memo[`${i}`] = param;
                            return memo;
                        },
                        {}
                    )
                ];
            }
            return params;
        };

        return (key, ...params) => {
            if (key === null || key === undefined) {
                return '';
            }

            try {
                // eslint-disable-next-line
                const value = fn(key, ...convertParams(params));
                if (key === value) {
                    console.warn(
                        `%ci18n missing: %c${value}`,
                        'color:#94999d',
                        'color:#000000'
                    );
                }
                return value;
            } catch (e) {
                console.warn(e.message);
                return '';
            }
        };
    }

    @computed
    public get translationsReady(): boolean {
        return this.version > 0;
    }
}
