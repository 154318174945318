import { observer } from 'mobx-react';
import * as React from 'react';
import { external, inject } from 'tsdi';
import { I18n } from '../core/i18n';

interface ErrorBoundaryState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
}

@observer
@external
export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    @inject
    private i18n!: I18n;

    constructor(props: {}) {
        super(props);

        this.state = {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public componentDidCatch(error: any, info: any): void {
        console.error(error);
        console.info(info);
        this.setState({ error });
    }

    public render(): React.ReactNode {
        if (this.state.error) {
            return <div>{this.i18n.__('commons.error.runtime')}</div>;
        }

        return this.props.children;
    }
}
