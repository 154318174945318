import * as React from 'react';

export const GTMNoScript = () => (
    /** Google Tag Manager (noscript) */
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-TMZZ6G77"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
    </noscript>
);
