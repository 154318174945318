import { bind } from 'lodash-decorators';
import { computed, observable, reaction } from 'mobx';
import {
    link,
    match,
    navigate,
    RouteConfig,
    Router as TakemeRouter
} from 'takeme';
import { component, initialize, inject, TSDI } from 'tsdi';
import './location';
import { Route, Routes } from './routes';
import { Url } from './url';
import './window';

export interface RouteEntry {
    title: string;
    active: boolean;
    link: keyof Links;
}

export interface Params {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [param: string]: any;
}

export interface Links {
    indexPublic(): string;
    login(): string;
    localizedPublic(): string;
    verification(): string;
    activation(): string;
}

export const links: Links = {
    indexPublic: () => '/',
    login: () => '/login',
    verification: () => '/verify*',
    activation: () => '/activate*',
    localizedPublic: () => '/:lang'
};

@component
export class AppRouter {
    @inject
    private tsdi!: TSDI;

    @inject
    private url!: Url;

    @inject
    private routes!: Routes;

    @inject('Location')
    private location!: Location;

    @inject('Window')
    private window!: Window;

    @observable
    public route = '';

    @observable
    public path = '';

    @observable
    public params: Params = {};

    @observable
    private locationSearch!: string;

    public router = new TakemeRouter(this.createRoutes());

    private scopes: string[] = [];

    @initialize
    public init(): void {
        this.router.enableHtml5Routing().init();

        reaction(
            () => [this.route, this.currentLink],
            ([route, currentLink]) => {
                this.manageScopes(route);
                if (currentLink === undefined) {
                    navigate(links.indexPublic());
                }
            },
            { fireImmediately: true }
        );

        reaction(
            () => this.url.historyVersion,
            () => this.onpopstate()
        );

        this.locationSearch = this.location.search;
        this.window.onpopstate = this.onpopstate;
    }

    private onpopstate(): void {
        this.locationSearch = this.location.search;
    }

    private createRoute(route: string): RouteConfig {
        return {
            $: route,
            beforeEnter: async ev => {
                const { newPath, oldPath, params } = ev;

                const routeObj = this.getRouteObj(newPath);

                if (routeObj) {
                    if (routeObj.beforeEnter) {
                        const beforeEnterResult = routeObj.beforeEnter({
                            newPath,
                            oldPath,
                            params
                        });

                        if (beforeEnterResult) {
                            return beforeEnterResult;
                        }
                    }
                }

                this.path = newPath;
                this.params = params;

                this.window.scrollTo(0, 0);

                return;
            },
            enter: ev => {
                this.route = route;
                const routeObj = this.getRouteObj(this.path);

                if (routeObj && routeObj.enter) {
                    routeObj.enter(ev);
                }
            }
        };
    }

    private createRoutes(): RouteConfig[] {
        return Object.keys(links).map(key =>
            this.createRoute(links[key as keyof Links]())
        );
    }

    private getRouteObj(path: string) {
        const link = this.getLink(path);

        if (link) {
            return this.getRoute(link);
        }

        return undefined;
    }

    @bind
    @computed
    public get createLink(): (
        path: keyof Links,
        ...params: (string | number)[]
    ) => string {
        return path =>
            link(links[path]()).replace(
                /^\.\//,
                `.${this.location.pathname}${this.locationSearch}`
            );
    }

    @computed
    public get currentLink(): keyof Links | undefined {
        return this.getLink(this.path);
    }

    public getLink(path: string) {
        // eslint-disable-next-line no-unused-vars
        const res = Object.entries(links).find(([_, value]) => {
            const pattern = value();
            const pathMatch = match({ pattern, path });
            if (pathMatch && !pathMatch.remainingPath) {
                return true;
            }
            return false;
        });

        if (res) {
            return res[0] as keyof Links;
        }

        return undefined;
    }

    public getRoutePath(
        link: keyof Links = this.currentLink || 'indexPublic'
    ): Route[] {
        const route = this.getRoute(link);
        const routes: Route[] = [];

        if (route) {
            routes.push(route);

            let { parent } = route;

            while (parent) {
                routes.push(parent);

                parent = parent.parent;
            }
        }

        return routes.reverse();
    }

    public linkIsActive(link: keyof Links) {
        if (this.currentLink) {
            const routes = this.getRoutePath(this.currentLink);

            return routes.some(route => route.link === link);
        }

        return false;
    }

    private enterScope(scope: string): void {
        if (!this.scopes.includes(scope)) {
            this.tsdi.getScope(scope).enter();

            this.scopes.push(scope);
        }
    }

    private leaveScope(scope: string, checkRoute = false): void {
        if (checkRoute && this.route === scope) {
            return;
        }

        if (this.scopes.includes(scope)) {
            this.tsdi.getScope(scope).leave();

            this.scopes = this.scopes.filter(s => scope !== s);
        }
    }

    private manageScopes(route?: string): void {
        if (!route) {
            return;
        }

        // leave scopeIds
        const scopesToLeave = new Set<string>();
        const scopesToEnter = new Set<string>();

        Object.keys(links).forEach(key => {
            const linkFn = links[key as keyof Links];
            const link = linkFn();
            const scope = route === link ? link : undefined;

            scope ? scopesToEnter.add(scope) : scopesToLeave.add(link);
        });

        scopesToLeave.forEach(scope => this.leaveScope(scope));
        scopesToEnter.forEach(scope => this.enterScope(scope));
    }

    @bind
    public getRoute(
        link: keyof Links,
        routes = this.routes.routes
    ): Route | null {
        return routes.reduce((memo, route) => {
            if (memo) {
                return memo;
            }

            if (route.link === link) {
                return route;
            }

            if (route.children) {
                return this.getRoute(link, route.children);
            }

            return memo;
        }, null as Route | null);
    }

    @bind
    public async createRouteEntries(
        link = this.currentLink!
    ): Promise<RouteEntry[]> {
        const { currentLink } = this;
        const routePath = currentLink ? this.getRoutePath(currentLink) : null;
        const route = this.getRoute(link);

        if (route && route.children) {
            return (
                await Promise.all(
                    route.children.map(async route => {
                        const active = routePath
                            ? routePath.some(item => item.link === route.link)
                            : false;

                        const title = await route.title();
                        return {
                            title,
                            active,
                            link: route.link
                        };
                    })
                )
            ).filter(item => item.title);
        }
        return [];
    }
}
