import { external, inject, TSDI } from 'tsdi';
import { TsdiDependency } from './tsdi';

@external
class Injector {
    @inject
    private tsdi!: TSDI;

    public get<T>(Dependency: TsdiDependency<T>, hint = ''): T {
        if (typeof Dependency === 'string') {
            return this.tsdi.get(Dependency);
        }

        return this.tsdi.get(Dependency, hint);
    }
}

class InjectorFactory {
    private injector?: Injector;

    public get<T>(Dependency: TsdiDependency<T>, hint?: string): T {
        if (!this.injector) {
            this.injector = new Injector();
        }

        return this.injector.get(Dependency, hint);
    }
}

export const tsdiInjector = new InjectorFactory();
