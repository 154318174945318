import { createTheme } from '@material-ui/core';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1260,
            xl: 1920
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                a: {
                    textDecoration: 'none',
                    color: '#4977dd'
                }
            }
        }
    },
    typography: {
        fontFamily: [
            '"Source Sans Pro"',
            '"Helvetica Neue"',
            'sans-serif',
            'Arial'
        ].join(','),
        h4: {
            fontWeight: 'bold',
            fontSize: '1.75rem'
        },
        subtitle1: {
            fontWeight: 'bold',
            fontSize: '1.125rem' // 18px
        },
        subtitle2: {
            fontWeight: 'normal',
            fontSize: '1.125rem' // 18px
        },
        button: {
            fontWeight: 'bold'
        }
    },
    palette: {
        primary: {
            main: '#4977dd',
            contrastText: '#fff'
        },
        secondary: {
            main: '#85ccab'
        }
    }
});

export { theme };
