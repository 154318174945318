import { observable } from 'mobx';
import { component, inject } from 'tsdi';
import './location';
interface StringArray {
    [index: string]: string;
}

@component
export class Url {
    @observable
    public historyVersion = 0;

    @inject('Location')
    public location!: Location;

    public get urlParams(): string {
        return this.location.search.replace(/(^\?)/g, '');
    }

    public get isDev(): boolean {
        const { hostname } = this.location;
        const matches = /(dev|local)?\.?magicline\.com/.exec(hostname);

        if (/localhost/.exec(hostname)) {
            return true;
        }

        if (matches) {
            const [, env] = matches;

            if (env === 'local' || env === 'dev') {
                return true;
            }
        }

        return false;
    }

    public getQueryParam(key: string) {
        const url = new URL(window.location.href);

        return url.searchParams.get(key);
    }

    public getQueryParamsFromUrl() {
        return window.location.hash
            ?.split('?')[1]
            ?.split('&')
            ?.reduce(function(result: StringArray, param) {
                const [key, value] = param.split('=');
                result[key] = value;
                return result;
            }, {});
    }
}
