import * as React from 'react';

const defaultTitle = 'Magicline Sign-up';

export const useBrowserTitle = (title: string) => {
    React.useEffect(() => {
        document.title = title ? title : defaultTitle;
        return () => {
            document.title = defaultTitle;
        };
    }, [title]);
};
