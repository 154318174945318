import 'core-js/features/array';
import 'core-js/features/promise';
import 'core-js/features/string';
import * as React from 'react';
import { render } from 'react-dom';
import { TSDI } from 'tsdi';
import 'tsdi-multi-scope';
import 'whatwg-fetch';
import { App } from './app';
import '../assets/analytics-scripts/bingtag-setup.js';
import '../assets/analytics-scripts/facebook-pixel-setup.js';
/* import '../assets/analytics-scripts/gtag-config.js'; */

const tsdi = new TSDI();

tsdi.enableComponentScanner();

render(<App />, document.getElementById('app'));
