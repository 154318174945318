import * as React from 'react';
import { RouteBeforeEnterResult, RouteEnterEvent } from 'takeme';
import { component, initialize, inject } from 'tsdi';
import { CountryCodes, I18n, countryLocaleMapping } from './i18n';
import { Links, Params } from './router';

export interface Route {
    link: keyof Links;
    children?: Route[];
    parent?: Route;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component?: React.LazyExoticComponent<any>;

    linkParams?(...params: unknown[]): Params;

    title(params?: Params): Promise<string>;

    beforeEnter?(options: RouteEnterEvent): RouteBeforeEnterResult;

    enter?(options: RouteEnterEvent): void;
}

@component
export class Routes {
    public routes: Route[] = [];

    @inject
    public i18n!: I18n;

    @initialize
    public async init() {
        this.routes = [
            {
                link: 'indexPublic',
                title: async () => 'Home',
                Component: React.lazy(() => import('../pages/createtenant'))
            },
            {
                link: 'login',
                title: async () => 'login',
                Component: React.lazy(() => import('../pages/login'))
            },
            {
                link: 'localizedPublic',
                title: async () => 'Home',
                beforeEnter: ({ newPath }) => {
                    if (newPath === '/en') {
                        return { redirect: '/uk' };
                    }
                    return;
                },
                enter: ({ params: { lang } }) => {
                    if (
                        lang &&
                        Object.prototype.hasOwnProperty.call(
                            countryLocaleMapping,
                            lang.toUpperCase()
                        )
                    ) {
                        this.i18n.currentCountry = lang.toUpperCase() as CountryCodes;
                    }
                },
                Component: React.lazy(() => import('../pages/createtenant'))
            },
            {
                link: 'verification',
                Component: React.lazy(() => import('../pages/verification')),
                title: async () => 'Email verified'
            },
            {
                link: 'activation',
                Component: React.lazy(() => import('../pages/activation')),
                title: async () => 'Email activated'
            }
        ];
        this.assignRouteParents();
    }

    private assignRouteParents(
        routes = this.routes,
        parent: Route | null = null
    ): void {
        routes.forEach(route => {
            if (!route.parent && parent) {
                route.parent = parent;
            }

            if (route.children) {
                this.assignRouteParents(route.children, route);
            }
        });
    }
}
